import { AppSidemenuComponent } from "./components/app-sidemenu/app-sidemenu.component";
import { EinsatzplanungPage } from "./pages/einsatzplanung/einsatzplanung.page";
import { AuthService } from "src/app/services/auth.service";
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy
} from "@angular/core";
import { Router } from "@angular/router";
import { Platform, MenuController } from "@ionic/angular";
// import { SplashScreen } from "@ionic-native/splash-screen/ngx";
// import { StatusBar } from "@ionic-native/status-bar/ngx";

import { User } from "./_models/user";
import { AuthenticationService } from "./services/authentication.service";
import { DynamicMenuService } from "./services/dynamic-menu.service";
import { register } from 'swiper/element/bundle';
register();
import { Subscription } from 'rxjs';


@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  public rootPage: any;
  public selectedIndex = 0;
  public image: string;
  public user?: User | null;

  @ViewChild(AppSidemenuComponent, { static: false })
  asc!: AppSidemenuComponent;
  @ViewChild("marker", { read: ElementRef })
  marker!: ElementRef;

  public appPages!: any[];
  private Menu_Subscription!: Subscription;
  private Authentication_Subscription = new Subscription();

  public labels = ["Family", "Friends", "Notes", "Work", "Travel", "Reminders"];

  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    private router: Router,
    private authService: AuthService,
    private menuCtrl: MenuController,
    private authenticationService: AuthenticationService,
    private dynamicMenuService: DynamicMenuService
  ) {
    this.image = 'https://randomuser.me/api/portraits/women/79.jpg';
    // this.initializeApp();
    console.log("Constructor app.component.ts wird aufgerufen");

    this.Authentication_Subscription = this.authenticationService.user.subscribe(user => {
      // this.authenticationService.user.subscribe(user => {
      this.user = user;
      // console.log("Test");
      // console.log(this.appPages);
    });

    this.Menu_Subscription = this.dynamicMenuService.menuObs.subscribe(x => {
      // this.dynamicMenuService.menuObs.subscribe(x => {
      // console.log("DynamicMenu Observable:")
      this.appPages = x;
      // console.log(this.appPages);
      this.menuCtrl.enable(true);
    })

    // this.authService.userSubject.subscribe(user => {
    //   if (user !== null) {
    //     console.log("USEROBJECT SUBSCRIBER AUFGERUFEN - Parameter: " + user.nachname);
    //     this.user = user
    //   }
    //   else {
    //     console.log("USEROBJECT SUBSCRIBER AUFGERUFEN - Parameter: " + "NULL");
    //   }
    //   // this.user = user
    // });


    // Wichtig. authuser is ein Behavior Subject. Der Code hinter subscribe wird also nicht direkt
    // aufgerufen, sondern es wird ein Observer erstellt.
    // Dieser wird mit der next-Methode auf den unterseiten aufgerufen.
    // authService.authUser.subscribe((jwt) => {
    //   console.log("AUTHUSER SUBSCRIBER aufgerufen");
    //   if (jwt) {
    //     this.router.navigateByUrl("/");
    //   } else {
    //     this.router.navigateByUrl("/login");
    //   }
    // });

  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();

      // this.menuCtrl.enable(true);
    });
  }

  ngOnInit() {
    // Prüfen zu welcher Usergrupper der User gehört und entsprechend das Neü aufbauen.
    // this.appPages = [
    //   {
    //     title: "Einsatzplanung",
    //     url: "/tabs/einsatzplanung",
    //     icon: "calendar",
    //   },

    //   {
    //     title: "Mitarbeiter",
    //     url: "/mitarbeiter",
    //     icon: "people",
    //   },
    //   {
    //     title: "Projekte",
    //     url: "/projekte",
    //     icon: "construct",
    //   },

    // ];


    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }
  }

  ngAfterViewInit() {
    console.log("ngAfterViewInit in app.component.ts wird audfgerufen");
    // this.dynamicMenuService.create_menu();
    let gruppe = this.authenticationService.userGruppe;
    console.log('Mitarbeiter gehört zur Gruppe:', gruppe)
    if (gruppe == "admin") {
      this.appPages = [
        {
          title: "Einsatzplanung",
          url: "/tabs/einsatzplanung",
          icon: "calendar",
        },

        {
          title: "Mitarbeiter",
          url: "/mitarbeiter",
          icon: "people",
        },

        {
          title: "Projekte",
          url: "/projekte",
          icon: "construct",
        },
      ];
    } else if (gruppe == "ma") {
      this.appPages = [
        {
          title: "Einsatzplanung",
          url: "/tabs/einsatzplanung",
          icon: "calendar",
        },

        {
          title: "Mitarbeiter",
          url: "/mitarbeiter",
          icon: "people",
        },
      ];
    }
    else if (gruppe == "pl") {
      this.appPages = [
        {
          title: "Einsatzplanung",
          url: "/tabs/einsatzplanung",
          icon: "calendar",
        },

        {
          title: "Mitarbeiter",
          url: "/mitarbeiter",
          icon: "people",
        },
        {
          title: "Projekte",
          url: "/projekte",
          icon: "construct",
        },

      ];
    }
    this.dynamicMenuService.sendMenu(this.appPages);

    // console.log(this.marker.nativeElement );

    // console.log(this.asc.testvar);
    this.marker.nativeElement.insertAdjacentHTML('beforeend', '<h2>Inserted</h2>')
    // this.authService.validate_token().then((result) => {
    //   if (result == true) {
    //     this.authService
    //       .get_sa_staff_group()
    //       .then((gruppe) => {
    //         console.log('Mitarbeiter gehört zur Gruppe:', gruppe)
    //         if (gruppe == "admin") {
    //           this.appPages = [
    //             {
    //               title: "Einsatzplanung",
    //               url: "/tabs/einsatzplanung",
    //               icon: "calendar",
    //             },

    //             {
    //               title: "Mitarbeiter",
    //               url: "/mitarbeiter",
    //               icon: "people",
    //             },

    //             {
    //               title: "Projekte",
    //               url: "/projekte",
    //               icon: "construct",
    //             },
    //           ];
    //         } else if (gruppe == "ma") {
    //           this.appPages = [
    //             {
    //               title: "Einsatzplanung",
    //               url: "/tabs/einsatzplanung",
    //               icon: "calendar",
    //             },

    //             {
    //               title: "Mitarbeiter",
    //               url: "/mitarbeiter",
    //               icon: "people",
    //             },
    //           ];
    //         }
    //         else if (gruppe == "pl") {
    //           this.appPages = [
    //             {
    //               title: "Einsatzplanung",
    //               url: "/tabs/einsatzplanung",
    //               icon: "calendar",
    //             },

    //             {
    //               title: "Mitarbeiter",
    //               url: "/mitarbeiter",
    //               icon: "people",
    //             },
    //             {
    //               title: "Projekte",
    //               url: "/projekte",
    //               icon: "construct",
    //             },

    //           ];
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("Fehler aufgetreten: ", err);
    //       });
    //   }
    // });
  }
  logout() {
    // this.authService.logout();
    this.menuCtrl.close();
    this.authenticationService.logout();
  }
  close_menu() {
    this.menuCtrl.close();
  }
  ngOnDestroy(): void {
    this.Menu_Subscription.unsubscribe();
    this.Authentication_Subscription.unsubscribe();
  }
}
